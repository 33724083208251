import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  // Set variables
  private token: string;
  private isAuthenticated = false;

  constructor(private http: HttpClient, private router: Router) { }

  // Get authenticated status
  getIsAuth() {
    return this.isAuthenticated;
  }

  // Get token
  getToken() {
    return this.token;
  } 
}
