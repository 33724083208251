<div class="sign-up" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center stretch"
    fxLayoutGap="0px">
    <div fxFlex="45%" fxHide.xs fxHide.sm>
        <div class="left-side">
            <div class="logo">
                <img class="text" src="assets/images/elektron-logo.svg" alt="Elektron Logo - Powering Your Future"
                    title="Elektron - Empowering Innovations for Tomorrow" />
            </div><br>
            <div class="sign-up-image">
                <img src="assets/images/steps/sign-up.svg" alt="Sign Up Steps - Create Your Account Today"
                    title="Join Now - Take the First Step Towards Your Journey" />
            </div>
        </div>
    </div>
    <div fxFlex="55%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="modal">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <div class="right-side">
                <div class="modal-header">
                    <div class="modal-title" [ngStyle.lt-md]="{'display': 'flex', 'justify-content': 'center'}">
                        <h2>Register with <span class="text-primary">ELEKTRON</span></h2>
                    </div>
                </div>
                <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                    <div class="modal-body modal-body-scroller">
                        <div class="modal-message">
                            <h3 class="text-center">Join us to explore exciting opportunities in the mobility business.
                            </h3>
                        </div>

                        <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row"
                            fxLayout.xl="row" fxLayoutGap="16px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px"
                            fxLayoutAlign="start stretch">
                            <div fxFlex="50%">
                                <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline"
                                    color="accent">
                                    <mat-label>First name*</mat-label>
                                    <input matInput type="text" formControlName="firstName" autocomplete="off" required>
                                    <mat-error *ngIf="registrationControls.firstName.hasError('required')">
                                        First name is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%">
                                <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline"
                                    color="accent">
                                    <mat-label>Last name*</mat-label>
                                    <input matInput type="text" formControlName="lastName" autocomplete="off" required>
                                    <mat-error *ngIf="registrationControls.lastName.hasError('required')">
                                        Last name is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                            <mat-label>Email address*</mat-label>
                            <input matInput type="text" formControlName="email" autocomplete="off" required>
                            <mat-error *ngIf="registrationControls.email.hasError('required')">
                                Email address is <strong>required</strong>
                            </mat-error>
                            <mat-error
                                *ngIf="registrationControls.email.hasError('email') && !registrationControls.email.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                            <mat-label>Contact number*</mat-label>
                            <input matInput type="text" formControlName="mobile" minlength="10" maxlength="10"
                                autocomplete="off" required>
                            <mat-error *ngIf="registrationControls.mobile.hasError('required')">
                                Contact number is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="registrationControls.mobile.hasError('minlength')">
                                Contact number must be <strong>10 digits</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                            <mat-label>Country*</mat-label>
                            <mat-select formControlName="country">
                                <ng-container *ngFor="let country of countries">
                                    <mat-option [value]="country.name">{{country.name}}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="registrationControls.country.hasError('required')">
                                Country is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                            <mat-label>Company name*</mat-label>
                            <input matInput type="text" formControlName="registeredCompanyName" autocomplete="off"
                                required>
                            <mat-error *ngIf="registrationControls.registeredCompanyName.hasError('required')">
                                Company name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                            <mat-label>Message</mat-label>
                            <textarea matInput type="text" formControlName="message" rows="2"
                                autocomplete="off"></textarea>
                        </mat-form-field>

                    </div>
                    <div class="modal-footer">
                        <div class="access">
                            <button mat-stroked-button (click)="onClose(false)">Cancel</button>
                            <button mat-flat-button type="submit" color="primary"
                                [disabled]="!registrationForm.valid">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>