<mat-sidenav-container>
    <mat-sidenav #drawer [ngClass]="{hidden: !(isHandset | async)!.matches}" fixedInViewport="false"
        [attr.role]="isHandset  ? 'dialog' : 'navigation'" [mode]="(isHandset | async)!.matches ? 'over' : 'side'"
        [opened]="!(isHandset | async)!.matches">
        <mat-toolbar>
            <mat-toolbar-row>
                <div class="logo">
                    <a [routerLink]="['/']" aria-label="Redirect to home page">
                        <img class="text" src="assets/images/elektron-logo-white.svg" alt="Elektron logo">
                    </a>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="drower-menus">
            <div class="access-section">
                <button mat-flat-button color="primary" (click)="onRegistration()" aria-label="Sign up">
                    <mat-icon>app_registration</mat-icon> Signup
                </button>
                <button mat-flat-button class="margin-left" color="primary" (click)="onLogin()" aria-label="Sign in">
                    <mat-icon>login</mat-icon> Signin
                </button>
            </div>
            <nav class="button-list-menus">
                <button class="action-menu" mat-menu-item [routerLink]="['/']" (click)="drawer.toggle()">
                    <div class="menu">
                        <mat-icon class="icon material-icons-outlined">home</mat-icon>
                        <span>Home</span>
                    </div>
                    <div class="action">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
                <!-- <button class="action-menu" mat-menu-item [routerLink]="['/pricing']" (click)="drawer.toggle()">
                    <div class="menu">
                        <mat-icon class="icon material-icons-outlined">business</mat-icon>
                        <span>Pricing</span>
                    </div>
                    <div class="action">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button> -->
                <button class="action-menu" mat-menu-item [routerLink]="['/features']" (click)="drawer.toggle()">
                    <div class="menu">
                        <mat-icon class="icon material-icons-outlined">business</mat-icon>
                        <span>Features</span>
                    </div>
                    <div class="action">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
                <button class="action-menu" mat-menu-item [routerLink]="['/partner-program']" (click)="drawer.toggle()">
                    <div class="menu">
                        <mat-icon class="icon material-icons-outlined">business</mat-icon>
                        <span>Partner Program</span>
                    </div>
                    <div class="action">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
            </nav>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="toolbar-container">
            <div class="toolbar" id="header" ngClass.xs="mobile" ngClass.sm="mobile" ngClass.md="tablet"
                ngClass.lg="desktop" ngClass.xl="desktop">
                <mat-toolbar>
                    <mat-toolbar-row>
                        <mat-icon class="drawer-icon" (click)="drawer.toggle()"
                            *ngIf="(isHandset | async)!.matches">menu
                        </mat-icon>
                        <div class="logo">
                            <a [routerLink]="['/']" aria-label="Redirect to home page">
                                <img class="text" src="assets/images/elektron-logo-white.svg" alt="Elektron logo" />
                            </a>
                        </div>
                        <span class="menu-spacer"></span>
                        <nav class="menus" fxHide.xs fxHide.sm>
                            <ul>
                                <li>
                                    <a [routerLink]="['/']" [class.active]="isLinkActive('/.')">
                                        <span>Home</span>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a [routerLink]="['/pricing']" routerLinkActive="active">
                                        <span>Pricing</span>
                                    </a>
                                </li> -->
                                <li>
                                    <a [routerLink]="['/features']" routerLinkActive="active">
                                        <span>Features</span>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['/partner-program']" routerLinkActive="active">
                                        <span>Partner Program</span>
                                    </a>
                                </li>
                                <li>
                                    <button mat-flat-button (click)="onRegistration()" aria-label="Sign up"
                                        color="primary">
                                        <mat-icon>app_registration</mat-icon> Signup
                                    </button>
                                </li>
                                <li>
                                    <button mat-flat-button aria-label="Sign in" (click)="onLogin()" color="primary">
                                        <mat-icon>login</mat-icon> Signin
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>