import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const BACKEND_URL_SERVICE = environment.apiBaseUrl + "/accounts";

const HEADERS = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer ' + environment.webToken,
    'Username': environment.webUser
  })
}
@Injectable({
  providedIn: 'root'
})
export class PartnerProgramService {

  constructor(private http: HttpClient, private router: Router) { }

  // Registration
  registration(registrationDetails: any) {
    return this.http.post<{ message: string }>(BACKEND_URL_SERVICE + '/registrations', registrationDetails, HEADERS);
  }
}
