import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AccessService } from 'src/app/public/access/access.service';
import { RegistrationFormComponent } from 'src/app/public/partner-program/partner-program/registration-form/registration-form.component';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Set variables 
  isLoading = false;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  @ViewChild('drawer') drawerRef: MatSidenav;

  constructor(private accessService: AccessService, private breakpointObserver: BreakpointObserver, private router: Router, public snackbarService: SnackbarService, public dialog: MatDialog) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let headerElement = document.getElementById('header');
    if (headerElement) {
      if (window.pageYOffset > 0) {
        headerElement.classList.add('white-toolbar');
      } else {
        headerElement.classList.remove('white-toolbar');
      }
    }
  }

  ngOnInit(): void {

  }

  // On registration
  onRegistration() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.backdropClass = 'backdrop-blur';
    dialogConfig.autoFocus = true;
    dialogConfig.width = "900px";
    this.dialog.open(RegistrationFormComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // On login
  onLogin() {
    window.location.href = environment.manageUrl + '/access';
  }

  // Check for home active
  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }
}