import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackbarService } from '../notifications/snackbar.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(public snackbarService: SnackbarService) { }

    // Check for http response errors
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = "An unknown error occured!";
                if (error.error.message) {
                    errorMessage = error.error.message;
                }
                this.snackbarService.error(errorMessage, 'Dismiss', 'bottom', 4000);
                return throwError(error);
            })
        );
    }
}