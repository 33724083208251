import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: HeaderComponent,
    loadChildren: () => import("./layout/layout.module").then(m => m.LayoutModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: "Page Not Found - Elektron",
      description: "Sorry, the page you're looking for could not be found. Please navigate back to the homepage.",
      isNoIndexed: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
